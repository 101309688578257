<script setup lang="ts">
interface Props {
  duration?: number;
  transition?: string;
  scale?: number;
}

const props = withDefaults(defineProps<Props>(), {
  duration: 300,
  transition: "ease",
  scale: 0,
});

const beforeEnter = (el: HTMLElement) => {
  el.style.scale = props.scale.toString();
  el.style.opacity = "0";
  el.style.transition = `scale ${props.duration}ms ${props.transition}, opacity ${props.duration}ms ${props.transition}`;
};

const enter = (el: HTMLElement) => {
  requestAnimationFrame(() => {
    el.style.scale = "1";
    el.style.opacity = "1";
  });
};

const leave = (el: HTMLElement) => {
  el.style.scale = props.scale.toString();
  el.style.opacity = "0";
};
</script>

<template>
  <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <slot></slot>
  </transition>
</template>

<style lang="scss"></style>
