<script setup lang="ts"></script>

<template>
  <div class="menu-item"><slot></slot></div>
</template>

<style lang="scss" scoped>
.menu-item {
  font-size: 2rem;
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
